import "./App.css";

function App() {
  const isMobileDevice = () => {
    return window.innerWidth <= 768;
  };

  const openTelegram = () => {
    window.open("https://telegram.me/+bl1DwAdFTtU0ODVl", "_blank");
  };

  return (
    <div
      style={{
        margin:0,
        padding: 0,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        height:'93vh',
        width:'100vw',
      }}
      onClick={isMobileDevice() ? openTelegram : null}
    >
      <div
        style={{
          // background:'rgb(1, 1, 70)',
          background: "#333333",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin:'1rem',
          height: "95%",
          width: "95%",
          borderRadius: "0.8rem",
          color: "white",
        }}
      >
        <div>
          <div
            className="archivo-black-regular"
            style={{ background: "#0A4775", padding: "0.7rem" }}
          >
            DADA SAHAB
          </div>
          <img
            src="sahab.jpg"
            alt="DADA"
            style={{
              height: "20vh",
              width: "30wh",
              borderRadius: "50%",
              marginTop: "1.5rem",
              marginBottom: "0.7rem",
            }}
          />
          <div style={{margin:'1rem 0', padding:0}}>
            <p style={{fontSize:'1rem'}}>𝗜𝗣𝗟 - 𝗣𝗦𝗟 - 𝗕𝗕𝗟 𝗙𝗨𝗟𝗟 𝗙𝗜𝗫𝗘𝗗 𝗔𝗩𝗔𝗜𝗟𝗔𝗕𝗟𝗘.</p>
            <p style={{fontSize:'1rem'}}>𝟭𝟬𝟬 % 𝗦𝗨𝗥𝗘 𝗧𝗢𝗦𝗦 - 𝗠𝗔𝗧𝗖𝗛 - 𝗦𝗘𝗦𝗦𝗜𝗢𝗡𝗦 𝗔𝗩𝗔𝗜𝗟𝗔𝗕𝗟𝗘.</p>
          </div>
          <p style={{fontSize:'1.8rem', margin:'1rem 0'}}>🎭 𝗥𝗘𝗔𝗟 𝗜𝗣𝗟 𝗙𝗜𝗫𝗘𝗥 🎭</p>
          <p style={{ background: "#0A4775", padding: "0.7rem" , margin:'1.2rem 0', fontSize:'1rem'}}>
            𝗪𝗢𝗥𝗟𝗗 𝗥𝗘𝗖𝗢𝗥𝗗 𝗜𝗡 𝗜𝗣𝗟 𝟮𝟬𝟮𝟯 𝟳𝟮/𝟳𝟰 𝗠𝗔𝗧𝗖𝗛 𝗣𝗔𝗦𝗦 𝗜𝗡 𝗧𝗘𝗟𝗘𝗚𝗥𝗔𝗠 𝗛𝗜𝗦𝗧𝗢𝗥𝗬.
          </p>
          <p
            style={{
              background: "#0A4775",
              padding: "0.7rem",
              fontWeight: "bold",
              margin:'1.5rem 0'
            }}
          >
            👇 𝟭𝟬𝟬% 𝗙𝗜𝗫𝗘𝗗 𝗜𝗣𝗟 𝗥𝗘𝗣𝗢𝗥𝗧𝗦  👇
          </p>
          <button>
            <div
              className="button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <img
                  src="logo.webp"
                  style={{
                    height: "2rem",
                    width: "2rem",
                    display: "inline",
                    margin:0,
                    padding:0
                  }}
                  alt="TELE"
                />
              </div>
              <div className="join">&nbsp;&nbsp;JOIN TELEGRAM</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
